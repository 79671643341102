import React from "react";

function AdminPage() {
    return (
      <div>
      <h3>Admin Page</h3>
      </div>
    );
  }


  export default AdminPage;